<template>
  <div class="advanced-select" ref="$this">
    <slot />
    <div :class="`advanced-select_dropdown ${!items.length ? '-empty' : ''}`">
      <ul class="advanced-select_items">
        <li class="advanced-select_item" v-for="item in <TAdvancedSelectItem[]>items"
          :key="(<TAdvancedSelectItem>item).id" v-on:click="selectItem(item as TAdvancedSelectItem)"
          v-on:keypress.enter="selectItem(item as TAdvancedSelectItem)" tabindex="0">
          {{ typeof item.label === 'function' ? item.label(item) : item.label }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import './advancedSelect.css';
import type {
  TAdvancedSelect,
  TAdvancedSelectItem,
} from './advancedSelect.type.js';

const $this = ref()
let $input: HTMLInputElement | null = null

const props = withDefaults(defineProps<TAdvancedSelect>(), {
  items: () => [],
  onSelect: () => { },
})

function selectItem(item: TAdvancedSelectItem): void {
  props.onSelect(item)

  // reset the input
  if ($input) {
    $input.value = '';
    $input.blur();
  }
}

onMounted(() => {
  $input = $this.value?.querySelector('input');
})

</script>